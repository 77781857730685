<template>
  <div>
    <div
      style="position: absolute;top:10%;width:100%;z-index:99;"
      v-if="active && !flag_student"
    >
      <!-- 因为postcss-pxtorem库会自动将非行内样式的px转换为rem,而该页面采用固定宽高 所以全部采用行内样式 -->
      <div
        style="width:340px;margin:0 auto;position:relative"
        v-show="!flag"
      >
        <img
          v-for="(item,index) in imgList"
          :key="index"
          @click="linkFn(item.link)"
          style="width:100%"
          :src="item.src"
          alt=""
        >
        <div style="width:74px;height:74px;border-radius: 50%;position: absolute;top:5%;left:38.9%;background-color:#fff;overflow: hidden;">
          <img
            style="width:100%;height:100%"
            :src="user.headimgurl"
            alt=""
          >
        </div>
        <p style="font-size:14px;color:#967b3c;margin: 0;position: absolute;top: 119px;left: 50%;text-align:center;transform: translateX(-50%);min-width: 180px;">
          来自{{ nicknameFn(user.nickname, 6) }}的助力</p>
        <p style="font-size:16px;color:#746253;margin: 0;position: absolute;top: 200px;left: 50%;text-align:center;transform: translateX(-50%);min-width: 180px;">
          您已为您的好友</p>
        <p style="font-size:16px;color:#746253;margin: 0;position: absolute;top: 230px;left: 50%;text-align:center;transform: translateX(-50%);min-width: 260px;">
          @{{ nicknameFn(refer.nickname, 8) }} &nbsp;增加助力值</p>
        <p style="font-size:14px;color:#746253;margin: 0;position: absolute;top: 328px;left: 50%;text-align:center;transform: translateX(-50%);min-width: 260px;">
          每人仅可助力成功一次，反复助力无效哦</p>
        <!-- <p
          style="text-decoration: underline;font-size:16px;color:#746253;margin: 0;position: absolute;top: 370px;left: 50%;text-align:center;transform: translateX(-50%);min-width: 260px;"
          @click="goPoster"
        >{{ link_text }}</p> -->

        <!-- 跳转到海报链接修改为button -->
        <button
          style="font-size:16px;margin: 0;position: absolute;top: 370px;left: 50%;text-align:center;transform: translateX(-50%);"
          name="button"
          class="button"
          @click="goPoster">{{ link_text }}</button>

        <p
          style="text-decoration: underline;font-size:16px;color:#746253;margin: 0;position: absolute;top: 405px;left: 50%;text-align:center;transform: translateX(-50%);min-width: 260px;"
          @click="goMy"
        >{{ profile_link }}</p>
      </div>

    </div>
    <!-- 学院系统展示相关内容 -->
    <div
      style="position: absolute;top:2%;width:100%;z-index:99"
      v-if="active && flag_student "
    >
      <div style="width:340px;margin:0 auto;position:relative">
        <img
          v-for="(item,index) in imgList"
          :key="index"
          @click="linkFn(item.link)"
          style="width:100%"
          :src="item.src"
          alt=""
        >
        <p
          style="text-decoration: underline;font-size:16px;color:#746253;margin: 0;position: absolute;top: 405px;left: 50%;text-align:center;transform: translateX(-50%);min-width: 260px;"
          @click="goStudent"
        >{{ text }}</p>

        <!-- 销售二维码 -->
        <div
          v-if="qrcode_flag"
          class="qrcode"
          style="width:60px;height:60px"
        >
          <vue-qr
            class="qrcode-img"
            :text="qrcode_uri"
            :size="100"
            :margin="2"
          ></vue-qr>
        </div>
        <!-- <p
          id="target"
          style="text-decoration: none;font-size:16px;color:#746253;margin: 0;position: absolute;top: 435px;left: 50%;text-align:center;transform: translateX(-50%);min-width: 260px;"
        >{{task_text}}</p>
        <button
          type="button"
          @click="copyFn"
          style="margin: 0;position: absolute;top: 475px;left: 50%;text-align:center;transform: translateX(-50%);min-width: 40px;"
        >复制</button> -->
      </div>
    </div>

    <!-- 信息获取失败提示 -->
    <div
      class="tip-box"
      v-show="flag"
    >
      <div style="font-size:18px;text-align: center; line-height:1;">提示</div>
      <div style="font-size:16px;text-align: center; margin-top:5px;line-height:1;">{{ errmsg }}</div>
    </div>

    <!-- 嵌入poster页面的iframe -->
    <div class="poster">
      <iframe
        :src="iframeUrl"
        width="100%"
        height="100%"
        frameborder="0"
      ></iframe>
    </div>

    <!-- 活动关闭展示的图片 -->
    <div v-if="!active">
      <img
        class="deactive_img"
        :src="deactive_img"
        alt=""
      >
      <!-- <img class="deactive_img"  :src="deactive_img" alt=""> -->
    </div>

  </div>
</template>

<script>
import axios from 'axios'
import VueQr from "vue-qr";

export default {
  components: {
    VueQr
  },
  data () {
    return {
      flag: false,
      errmsg: '',
      headimgurl: "https://ceshiren.com/uploads/default/original/1X/809c63f904a37bc0c6f029bbaf4903c27f03ea8a.png",
      nickname: "霍格沃兹测试学院",
      user: {
        headimgurl: '',
        nickname: ''
      },
      refer: {
        nickname: '',
        headimgurl: '',
      },
      title: '',
      query: {},
      imgList: '',
      iframeUrl: '',
      link_text: '',
      profile_link: '',
      active: false,
      deactive_img: '',
      flag_student: false,
      text: '',//学员系统相关问题链接text
      link: '',//学员系统相关链接link
      task_text: '测试使用复制文本到剪贴板',
      qrcode_uri: '',
      qrcode_flag: false
    }
  },
  methods: {
    //跳转到学员系统
    goStudent () {
      location.href = this.link
    },

    linkFn (src) {
      if (src) {
        location.href = src
      }
    },

    //复制文本到剪贴板
    copyFn () {
      console.log(1);
      let text = document.getElementById('target').innerText
      this.$copyText(text)
        .then(() => {
          console.log('复制成功');
        })
        .catch(() => {
          console.log('复制失败');
        })
    },

    //获取用户数据信息
    async getUserInfo () {
      let uid = this.$route.query.uid
      let project = this.$route.query.project
      let origin_ = location.origin
      // 加密请求数据
      // let reqData = this.$resCommon.reqEncrypt({ uid: uid, project: project });
      // origin_ = 'https://dev.poster.ceba.ceshiren.com/'
      // const res = await axios.post(`${origin_}/backend/verify`, { data: reqData })
      const res = await axios.post(`${origin_}/backend/verify`, { uid: uid, project: project })
      // const res = await axios.get(`https://dev.poster.ceba.ceshiren.com/backend/dev/verify?uid=${uid}`)
      console.log(res);
      if (res.data.errcode) {
        this.flag = true
        this.errmsg = res.data.errmsg
        return
      }
      // const resData = this.$resCommon.getResData(res)
      const resData = res.data.data
      if (resData.active) {
        this.active = resData.active
        this.user = resData.user
        this.refer = resData.refer
        this.title = resData.title
        this.query = resData.poster
        this.imgList = resData.img
        this.link_text = resData.to_poster_text
        this.profile_link = resData.to_profile_text
        //请求到数据后 iframe加载poster页面
        this.iframeUrl = `${origin_}/poster?project=${resData.poster.project}&refer=${resData.poster.refer}`
      } else {
        this.active = resData.active
        this.deactive_img = resData.deactive_img
      }
      //对于学院系统相关内容
      this.flag_student = resData.non_assist.flag
      if (resData.non_assist.flag) {
        this.text = resData.non_assist.text
        this.link = resData.non_assist.link
        if (resData.distribution.flag) {
          if (resData.distribution.link) {
            this.qrcode_uri = resData.distribution.link;
            this.qrcode_flag = resData.distribution.flag;
          }
        }
      }
      //distribution
    },


    //昵称处理
    nicknameFn (nickname, num) {
      if (nickname.length > num) {
        return nickname.substring(0, num) + '···'
      } else {
        return nickname
      }
    },

    //跳转poster页面
    goPoster () {
      this.$router.push({ path: 'poster', query: this.query })
    },

    //跳转my页面
    goMy () {
      //加密
      // const queryData = this.$resCommon.reqEncrypt(this.query)
      // this.$router.push({ path: 'my', query: { data: queryData } })
      this.$router.push({ path: 'my', query: this.query })
    }
  },
  created () {
    console.log('页面更新测试------------------');
    this.getUserInfo()
  },
  mounted () {
    console.log(this.title);
    if (this.title) document.title = this.title
    // else document.title = ''
  },
  watch: {
    title: {
      handler (newval, oldval) {
        if (newval !== oldval) document.title = this.title
      }
    }
  }
}
</script>

<style scoped>
.tip-box {
  width: 100%;
  margin-top: 20%;
}

.poster {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  opacity: 0;
}

.deactive_img {
  object-fit: contain;
  max-width: 375px;
  max-height: 670px;
  position: absolute;
  z-index: 99;
  left: 50%;
  transform: translateX(-50%);
}

.v-application--wrap {
  position: static !important;
}

.v-application--wrap p {
  line-height: 1.5;
}

.qrcode {
  /* width: 2rem;
  height: 2rem; */
  position: absolute;
  /* right: 1rem;
  bottom: 0.3rem; */
  z-index: 2;
  right: 7%;
  bottom: 3%;
}

.qrcode .qrcode-img {
  width: 100%;
  height: 100%;
}
.button {
  display: block;
  margin: 10px auto;
  line-height: 1.8;
  padding: 0 8px;
  background-color: #409eff;
  border-radius: 4px;
  color: #ffffff;
  font-size: 16px;
}
</style>